import { FeedbackRounded } from '@mui/icons-material';
import { Button, Theme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const FeedbackButton = () => {
    const { t } = useTranslation();
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <Button
            size="small"
            color="primary"
            variant="outlined"
            component={Link}
            to="//product.swonkie.com"
            target="_blank"
            startIcon={<FeedbackRounded />}
            sx={{ ...(isMd && { '& .MuiButton-startIcon': { marginRight: '0px' } }) }}
        >
            {isMd ? null : t('str.help.feature.feedback')}
        </Button>
    );
};
export default FeedbackButton;
