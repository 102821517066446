import MenuOpenRounded from '@mui/icons-material/MenuOpenRounded';
import MenuRounded from '@mui/icons-material/MenuRounded';
import {
    AppBar as MuiAppBar,
    Grid2 as Grid,
    Toolbar,
    Typography,
    AppBarProps as MuiAppBarProps,
    IconButton,
    Breadcrumbs,
    SvgIconProps,
    Link,
    useMediaQuery,
    Stack,
    Chip,
} from '@mui/material';
import { drawerWidth } from './nav/MainNav';
import { useNavigation } from '@/providers/navigation-provider';
import { getMenuPath, menu } from './nav/menu/Menu';
import { ReactNode, cloneElement, isValidElement } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { styled, Theme } from '@mui/material/styles';
import Cookies from 'js-cookie';
import { COOKIES } from '@/constants';
import { useTranslation } from 'react-i18next';
import FeedbackButton from './common/FeedbackButton';

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    ...(!open && {
        width: `calc(100% - ${theme.spacing(7)} - 1px)`,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    '&.MuiAppBar-root': {
        zIndex: theme.zIndex.drawer - 1,
    },
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

export default function NavBar({
    optionsComponent,
}: Readonly<{
    optionsComponent: React.ReactNode;
}>) {
    const { t } = useTranslation();
    const { mainNavOpen, setMainNavOpen, mainNavOpenAbsolute } = useNavigation();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const open = mainNavOpen && !mainNavOpenAbsolute;

    const pathname = useLocation().pathname;
    const menuSelected =
        menu.find((each) => {
            return pathname.startsWith(each.path);
        }) || menu[0];

    const subMenuSelected = menuSelected.sub?.find((each) => {
        return each.sub.some((item) => pathname.startsWith(menuSelected.path + item.path));
    });

    const subItemSelected = subMenuSelected?.sub?.find((item) =>
        pathname.startsWith(menuSelected.path + item.path),
    );

    const addPropsToIcon = (element: ReactNode, additionalProps: SvgIconProps): ReactNode => {
        if (isValidElement(element)) {
            return cloneElement(element, additionalProps);
        }
        return element;
    };

    const toggleNavBar = () => {
        Cookies.set(COOKIES.MAIN_NAV_OPENED, String(!mainNavOpen ? 1 : 0), { expires: 365 });
        setMainNavOpen(!mainNavOpen);
    };

    return (
        <AppBar
            sx={{
                borderBottom: 1,
                borderColor: 'elevation.outline-variant',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: 'background.paper',
            }}
            position="fixed"
            elevation={0}
            open={open}
        >
            <Toolbar variant="dense">
                <Grid container alignItems="center" flex={1}>
                    <Grid size={{ xs: 6 }} alignItems={'center'} display={'flex'}>
                        <IconButton onClick={toggleNavBar} sx={{ mr: menuSelected.beta ? 1 : 2 }}>
                            {open ? <MenuOpenRounded /> : <MenuRounded />}
                        </IconButton>
                        {menuSelected.beta && (
                            <Chip
                                color="secondary"
                                label="BETA"
                                size="small"
                                sx={{ '.MuiChip-label': { fontWeight: 500 }, mr: 1 }}
                            />
                        )}
                        <Breadcrumbs aria-label="breadcrumb" separator={isMobile ? '' : '/'}>
                            {(!isMobile || (isMobile && !subItemSelected)) && (
                                <Link
                                    component={RouterLink}
                                    underline="hover"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    color="inherit"
                                    to={getMenuPath(menuSelected)}
                                >
                                    {addPropsToIcon(menuSelected.icon, {
                                        sx: { mr: 1 },
                                        fontSize: 'inherit',
                                    })}
                                    {t(menuSelected.name)}
                                </Link>
                            )}
                            {subItemSelected && (
                                <Typography
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="text.primary"
                                >
                                    {t(subItemSelected.name)}
                                </Typography>
                            )}
                        </Breadcrumbs>
                    </Grid>
                    <Grid size={{ xs: 6 }}>
                        <Stack direction="row" justifyContent="end" spacing={1} alignItems="center">
                            <FeedbackButton />
                            {optionsComponent}
                        </Stack>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}
