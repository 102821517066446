import { CountUnreadNotificationsDocument } from '@/graphql/generated/count-unread-notifications.generated';
import { GroupNotificationsDocument } from '@/graphql/generated/group-notifications.generated';
import { NotificationsDocument } from '@/graphql/generated/notifications.generated';
import {
    ClearNotificationDocument,
    UnclearNotificationDocument,
} from '@/graphql/generated/toggle-clear-notification.generated';
import {
    ReadNotificationDocument,
    UnreadNotificationDocument,
} from '@/graphql/generated/toggle-read-notification.generated';
import { NotificationTab, SingleNotification } from '@/graphql/generated/types.generated';
import { useMutation } from '@apollo/client';
import {
    ClearAllRounded,
    MarkEmailReadRounded,
    MarkEmailUnreadRounded,
    MoreVertRounded,
    UndoRounded,
} from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const StyledIconButton = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== 'isOpenOptions' && prop !== 'absolute',
})<{ isOpenOptions: boolean; absolute?: boolean }>(({ isOpenOptions, absolute }) => ({
    ...(!isOpenOptions && { visibility: 'hidden' }),
    ...(absolute && {
        position: 'absolute',
        top: 0,
        right: 0,
        background: 'radial-gradient(circle, rgba(245,245,245,1) 50%, rgba(245,245,245,0) 100%)',
    }),
}));

type MoreOptionsProps = Pick<SingleNotification, 'isRead'> & {
    tab: NotificationTab;
    group?: number;
    id?: string;
    absolute?: boolean;
};
export const MoreOptionsButton = ({ id, group, isRead, tab, absolute }: MoreOptionsProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const [anchorOptions, setAnchorOptions] = useState<null | HTMLElement>(null);
    const [unclear] = useMutation(UnclearNotificationDocument, {
        variables: { id, group },
        refetchQueries: [NotificationsDocument, CountUnreadNotificationsDocument, GroupNotificationsDocument],
        onCompleted: () => enqueueSnackbar(t('snackbar.notification.single.restored')),
        onError: (e) => enqueueSnackbar(e.message),
    });
    const [clear] = useMutation(ClearNotificationDocument, {
        variables: { id, group },
        refetchQueries: [NotificationsDocument, CountUnreadNotificationsDocument, GroupNotificationsDocument],
        onCompleted: () => enqueueSnackbar(t('snackbar.notification.single.clear')),
        onError: (e) => enqueueSnackbar(e.message),
    });
    const [unread] = useMutation(UnreadNotificationDocument, {
        variables: { id, group },
        refetchQueries: [NotificationsDocument, CountUnreadNotificationsDocument, GroupNotificationsDocument],
        onCompleted: () => enqueueSnackbar(t('snackbar.notification.single.unread')),
        onError: (e) => enqueueSnackbar(e.message),
    });
    const [read] = useMutation(ReadNotificationDocument, {
        variables: { id, group },
        refetchQueries: [NotificationsDocument, CountUnreadNotificationsDocument, GroupNotificationsDocument],
        onCompleted: () => enqueueSnackbar(t('snackbar.notification.single.read')),
        onError: (e) => enqueueSnackbar(e.message),
    });
    const { t } = useTranslation();

    const openOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorOptions(event.currentTarget);
    };

    const closeOptions = () => {
        setAnchorOptions(null);
    };

    const toggleClearNotification = () => {
        if (tab === NotificationTab.Cleared) {
            unclear();
        } else {
            clear();
        }
        closeOptions();
    };

    const toggleReadNotification = () => {
        if (isRead) {
            unread();
        } else {
            read();
        }
        closeOptions();
    };

    const isOpenOptions = Boolean(anchorOptions);
    return (
        <div>
            <StyledIconButton
                size="small"
                onClick={openOptions}
                isOpenOptions={isOpenOptions}
                absolute={absolute}
            >
                <MoreVertRounded fontSize="small" />
            </StyledIconButton>
            <Menu
                anchorEl={anchorOptions}
                open={isOpenOptions}
                onClose={closeOptions}
                slotProps={{
                    root: {
                        sx: (theme) => ({
                            zIndex: theme.zIndex.snackbar,
                        }),
                    },
                    paper: {
                        sx: {
                            minWidth: 220,
                            borderRadius: 2,
                            border: 0,
                        },
                    },
                }}
            >
                <MenuItem onClick={toggleReadNotification}>
                    <ListItemIcon>
                        {isRead ? (
                            <MarkEmailUnreadRounded fontSize="small" />
                        ) : (
                            <MarkEmailReadRounded fontSize="small" />
                        )}
                    </ListItemIcon>
                    <ListItemText>
                        {isRead
                            ? t('notifications.modal.action.icon-button.checkall.mark-as-unread')
                            : t('notification.card.more-options.menu.item.mark-read')}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={toggleClearNotification}>
                    <ListItemIcon>
                        {tab !== NotificationTab.Cleared ? (
                            <ClearAllRounded fontSize="small" />
                        ) : (
                            <UndoRounded fontSize="small" />
                        )}
                    </ListItemIcon>
                    <ListItemText>
                        {tab !== NotificationTab.Cleared
                            ? t('notification.card.more-options.menu.item.clear')
                            : t('notification.card.more-options.menu.item.unclear')}
                    </ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
};
